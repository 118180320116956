<i18n>
	{
		"en": {
			"title": "Reset PIN",
			"enterCode": "Please enter code sent to your phone.",
			"didntGet": "Didn't get the code?",
			"clickMe": "Click me",
			"pinMsg": "You will be using PIN while performing transactions.",
			"newPin": "Enter New PIN",
			"verifyPin": "Verify New PIN",
			"confirm": "Confirm",
			"clear": "Clear"
		},
		"np": {
			"title": "PIN रिसेट गर्नुहोस्",
			"enterCode": "कृपया तपाईको फोनमा पठाइएको कोड राख्नुहोस्।",
			"didntGet": "कोड पाउनुभएन?",
			"clickMe": "क्लिक गर्नुहोस्",
			"pinMsg": "लेनदेन गर्दा तपाईले PIN प्रयोग गरिरहनु भएको छ।",
			"newPin": "नयाँ पिन राख्नुहोस्",
			"verifyPin": "नयाँ पिन प्रमाणित गर्नुहोस्",
			"confirm": "पुष्टि गर्नुहोस्",
			"clear": "स्पष्ट"
			}
	}
</i18n>

<template lang="pug">
	.container
		.wallet-form-box.card
			.card-body
				.row
					.col-sm-12.col-md-6
						h2 {{ $t("title") }}
						label {{ $t("enterCode") }}
						transition(name='fade' mode='out-in')
							div(v-if='otpsent')
								form(@submit.prevent='savePin')
								
									input.form-control(type='number' maxlength='6' minlength='6' v-model='otp' autocomplete="off" name="otp_verify" autofocus required)
									.div.mt-2.text-right
										small {{ $t("didntGet") }}
										u.small.cursor-pointer.ml-2(@click='requestOTP') {{ $t("clickMe") }}
									.form-group
										.show-message(v-if='message') {{message}}
									hr
									small.text-muted {{ $t("pinMsg") }}
									.form-row
										.form-group.col-sm-12
											label {{ $t("newPin") }}
											input.form-control(type='password' autofocus placeholder='Enter 6 digit PIN code' pattern='[0-9]+'  maxlength='6' minlength='6' v-model='pin' required)
										.form-group.col-sm-12
											label {{ $t("verifyPin") }}
											input.form-control(type='password' placeholder='Enter 6 digit PIN code' maxlength='6' minlength='6' v-model='pinVerify' required)
										.form-group.col-sm-12
											.show-message(v-if='message') {{message}}
										.form-group.col-sm-12
											button.btn.btn-lg.btn-primary(type='submit')
												.spinner-border.spinner-border-sm(role='status' v-if='loading' aria-hidden="true")
												.span(v-else) {{$t("confirm")}}
											button.btn.btn-lg.btn-secondary.ml-3(@click='reset' type='button') {{ $t("clear") }}
</template>

<script>
import axios from "@/axios";
export default {
	name: "change-pin",
	data() {
		return {
			pin: "",
			pinVerify: "",
			message: "",
			otpsent: true,
			otp: "",
			loading: false,
		};
	},
	async mounted() {
		const res = await axios.post("api/v1/walletpin/sendss");
		if (res.data.status_code === 404) {
			this.$router.push("/");
		}
	},
	methods: {
		reset() {
			this.pin = "";
			this.pinVerify = "";
		},
		async requestOTP() {
			await axios.post("api/v1/walletpin/send");
			this.message = "New OTP sent.";
		},
		savePin() {
			this.loading = true;
			if (this.pin === this.pinVerify) {
				axios
					.post("api/v1/walletpin/verify", { code: this.otp })
					.then((res) => {
						if (res.data.code === 1) {
							axios
								.patch("api/v1/walletpin/reset", {
									pin: this.pin,
								})
								.then((res) => {
									this.loading = false;
									if (res.data.code === 1) {
										this.$router.push("/");
									}
								});
						} else {
							this.message = "Invalid OTP code";
						}
					})
					.catch((er) => {
						this.message = "Invalid OTP code";
					});
			} else {
				this.message = "PIN do not match";
			}
		},
	},
};
</script>
